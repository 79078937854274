import React, {useEffect, useState} from "react";

import {
  withRouter
} from "react-router-dom"

import Footer from "./components/footer/footer";


import Home from "./components/home";
import Privacy from "./components/privacy/privacy";
import FormModal from "./components/forms/modal";
import Calculator from "./components/calcultor/Calculator";
import calculatorStore from "./components/calcultor/calculator.store";

import './assets/scss/global.scss'
import MapItemModal from "./components/models/MapItemModal";

function App(props) {
  const { history } = props
  const [modalActive, setModalActive] = useState(false);
  const [modalData, setModalData] = useState({})
  const [calculatorActive, setCalculatorActive] = useState(false)
  const [fraction, setFraction] = useState([])

  function modal(active, data) {
    setModalActive(active)
    setModalData(data)
  }

  function openCalculator(fractionData = null) {
    if(fractionData) {
      setFraction(fractionData)
    }
    setCalculatorActive(true)
  }

  useEffect(()=>{
    (async()=>{
      await calculatorStore.getQuarrys()
      console.log(123)
    })()
  }, [])



  return (
    
      <React.Fragment>
        {/* <Header/> */}
        {
          history.location.pathname === '/privacy' ?
            <Privacy/>
            :
            <>
              <Home modal={modal} calculator={openCalculator}/>
              <FormModal active={modalActive} setActive={setModalActive} data={modalData}/>
              <Calculator active={calculatorActive} setActive={setCalculatorActive} fractionData={fraction}/>
              <MapItemModal/>
            </>
        }
        <Footer/> 
      </React.Fragment>
    
  )
}



export default withRouter(App);
