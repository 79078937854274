const config = {
    url: 'https://втор-щебень.рф', 
    company: 'ООО «Нерудком»',
    meta_title: 'Вторичный щебень с доставкой по Москве и МО - 24/7',
    meta_description: 'Вторичный щебень напрямую от производителя предлагает компания ООО «Нерудком». У нас свой автопарк. Любая форма оплаты. Звони!',
    title: 'Вторичный бетонный щебень',
    descriptor: 'напрямую от производителя',
    offer: 'Мы поставляем качественный вторичный щебень разных фракций по низким ценам',
    time: 'Пн-Вс 8:00 — 23:00',
    phone: '+7(499)444-03-90',
    email: 'zakaz@nerudkom.ru',
    INN: '7708422517',
    OGRN: '1237700526359',
    whatsapp: '+79263900390',
    telegram: 'nerudkom_bot',
    "bitrix24WebHook": 'https://alfaspetsstroy.bitrix24.ru/rest/3814/5tpfrzplm9utg7up',
    "pole_phone": 'UF_CRM_2928E19C70542',
    "pole_name": 'UF_CRM_1623227175451',
    "pole_value": 'UF_CRM_82F235BF1C13D',
    "pole_address": 'UF_CRM_1628014047977',
    "CATEGORY_ID": 0,
    "funnel_id": '2'
}
export default config