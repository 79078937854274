const productsArray = [
    {
        title: 'Вторичный щебень',
        descriptor: 'фракции 5-20',
        fraction: '5-20',
        price: 600
    },
    {
        title: 'Вторичный щебень',
        descriptor: 'фракции 20-40',
        fraction: '20-40',
        price: 550
    },
    {
        title: 'Вторичный щебень',
        descriptor: 'фракции 40-70',
        fraction: '40-70',
        price: 450
    },
    {
        title: 'Вторичный щебень',
        descriptor: 'фракции 0-100',
        fraction: '0-100',
        price: 300
    },
    {
        title: 'Вторичный отсев',
        descriptor: 'фракции 0-5',
        fraction: '0-5 (отсев)',
        price: 100
    },
    {
        title: 'Бетонный щебень',
        descriptor: 'рецикл',
        fraction: '5-20',
        price: 600
    },

]
export default productsArray