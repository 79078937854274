import React, { useState } from "react";

import './us.scss'
import './adaptive.scss'


function Us() {
    const [page, setPage] = useState(1);
    return (
        <React.Fragment>
                <div className="aboutUs">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <div className="title">
                                    <span>О компании</span>
                                    <span><b>ООО «НЕРУДКОМ» —</b><br/>надежный партнер вашего бизнеса</span>
                                </div>
                                <div className="description">
                                    Располагаем внушительным парком собственного автотранспорта и карьерной техники, что позволяет снизить расходы клиента на доставку.
                                </div>
                            </div>
                            <div className="line"/>
                        </div>
                    </div>
                    <div className="slider">
                        <div className="container">
                            <div className="content">
                                <div className="btns">
                                    <div className={page === 1 ? "btn active" : "btn"} onClick={()=>{setPage(1)}}>Описание</div>
                                    <div className={page === 2 ? "btn active" : "btn"} onClick={()=>{setPage(2)}}>Область применения</div>
                                </div>
                                <div className="pages">
                                    {
                                        page === 1 ? (
                                            <>
                                                <span className="title">Вторичный щебень</span>
                                                <p>Вторичный щебень является продуктом переработки строительного мусора: железобетонных плит, кирпичных кладок домов и строений или асфальтовых покрытий дорог (асфальтовый скол).</p>
                                                <p>Соответственно, различают 3 основных вида вторичного щебня:</p>
                                                <div className="numberText">
                                                    <span>1. бетонного вторичного щебня;</span>
                                                    <span>2. кирпичного боя;</span>
                                                    <span>3. асфальтной или асфальтно-бетонной крошки.</span>
                                                </div>
                                                <p>В зависимости от возможностей дробилки, в процессе производства можно производить вторичный бетонный щебень разных фракций: от 5 до 100, а также отсев вторичного бетонного щебня. Кирпичный бой как правило на фракции не разделяют, а асфальтный вторичный щебень дробят на асфальтную крошку фракции 5-20 и асфальтовый вторичный щебень фракции 20-40.</p>
                                                <span className="title">Производство вторичного щебня</span>
                                                <p>Производство вторичного щебня зависит от возможностей дробилки, в которую загружаются бетонные плиты или блоки, которые в свою очередь получают из строительного мусора в ходе сноса и демонтажа жилых домов или промышленных сооружений. Как было отмечено выше, в процессе производства вторичного щебня могут использоваться кирпичи и старое асфальтовое покрытие (скол), которые также загружаются в дробилки.</p>
                                                <p>После измельчения сырья, полученный вторичный щебень отправляют в сортировку, в ходе которой происходит удаление из щебня металла, дерева и других примесей и его разделение на фракции.</p>
                                                <p>Дробилки в свою очередь разделяют на мобильные (как правило менее мощные и способные переработать относительно мелкие фрагменты строительного мусора и на выходе выдающие мелкие фракции вторичного щебня) и стационарные (способные дробить и крупные фрагменты строительного мусора и на выходе производящие вторичный щебень разных фракций, в том числе и крупные).</p>
                                                <span className="title">Бетонный вторичный щебень (рецикл)</span>
                                                <p>Рецикл (так ещё называют бетонный щебень) обладает рядом преимуществ над природным щебнем (гранитным, гранитным или известняковым), а именно:</p>
                                                <div className="numberText">
                                                    <span>1. Цена. Первое, и самое важное преимущество.</span> 
                                                    <span>2. Экономия может достигать 100 %.</span> 
                                                    <span>3. Сниженный объёмный вес: в одном кубометре как правило находится на 10-15 % больше материала по весу.</span>
                                                    <span>4. Повышенная адгезия, относительно природного щебня (за счёт остатков частиц цемента).</span>
                                                    <span>5. Экологичность, которая заключается в том, что один и тот же материал используется вторично и не надо <span></span>разрабатывать природные месторождения и соответственно, загрязнять природу.</span>
                                                    <span>6. Скорость производства и быстрота доставки.</span>
                                                    <span>7. Производство ограничено только наличием высокопроизводительной дробилки (как правило стационарной) и строительного мусора, из которого делают вторичный щебень.</span>
                                                </div>
                                                <p>На этом преимущества заканчиваются и мы можем перечислить несколько основных недостатков, которые ограничивают применение вторичного щебня (о которых вы можете прочитать в соответствующей вкладке):</p>
                                                <div className="numberText">
                                                    <span>1. Низкая прочность: рецикл относительно легко разрушается.</span>
                                                    <span>2. Вторичный щебень обычно служит в 2-3 раза меньше природного щебня.</span>
                                                    <span>3. Низкая морозоустойчивость (это один из факторов, обуславливающий низкую прочность).</span>
                                                </div>
                                            </>
                                        ): page === 2 ? (
                                            <>
                                                <p>Как было описано в Описании, существуют 3 основных вида вторичного щебня: бетонный щебень, кирпичный бой и асфальтовая крошка.<br/> Каждая разновидность вторичного щебня подходит под определённые виды работ.</p>
                                                <span className="title">Применение вторичного бетонного щебеня</span>
                                                <p>Основным применением вторичного бетонного щебня является основание для грунтовых дорог, пешеходных дорожек, прогулочных парков, а также дорог с малой нагрузкой и стоянок, в том числе и грузового транспорта.<br/>
                                                Идеальным материалом для основания грунтовых дорог (дорожной подушки) является смесь вторичного бетонного щебня и вторичного асфальтового щебня (асфальтной крошки или асфальтового щебня фракции 20-70).<br/>
                                                Помимо этого, вторичный бетонный щебень (фракции 5-20) подходит для бетонного раствора и ЖБИ, а отсев вторичного бетонного щебня может использоваться в качестве заменителя песка для бетона в производстве лёгких ЖБ изделиях.<br/>
                                                Иногда вторичный бетонный щебень используется для закрепления почв при сооружении инженерных сетей: тепловодоснабжения и водоотведения.</p>
                                                <span className="title">Применение кирпичного боя</span>
                                                <p>За счёт того, что кирпичи отлично впитывают влагу, кирпичный бой часто используют в дренажных системах (также, в дренаже используется и вторичный бетонный щебень).<br/>
                                                Не менее часто, кирпичный бой применяется во время отсыпки грунтовых дорог и временных оснований второстепенных сельских дорог (из-за дешевизны материала).<br/>
                                                Ну и за счёт того, что кирпичи бывают разных цветов, кирпичный бой используют в декоративных целях в отсыпке дорожек или элементов ландшафтного дизайна.</p>
                                                <span className="title">Применение асфальтовой крошки</span>
                                                <p>Практически единственным способом применения вторичного асфальтового щебня (крошки) это использование в дорожном строительстве. Иногда асфальтную крошку могут использовать в декоративных целях в ходе ландшафтных работ.</p>
                                            </>
                                        ): setPage(1)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Us;