import { useEffect, useState } from 'react';
import { useScrolWindow } from '../../hooks/useScrollWindow';
import calculatorStore from '../calcultor/calculator.store';
import { observer } from 'mobx-react-lite';
import { AddressSuggestions } from 'react-dadata';
import SelectModule from "react-select";
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import axios from 'axios';

import './style.scss'

import config from "../../config";

const InputAdressHelp = ({adress, setAdress}) => {
    return (
        <div className='input'>
            <span className='title'>Укажите адрес доставки</span>
            <AddressSuggestions
                token="1fab525a0826da67fbc9fbe9769bfda7da49c76d" 
                inputProps={
                    {placeholder: 'Например: Москва ул. Кадомцев, д. 3'}
                }
                // @ts-ignore
                value={adress}
                 // @ts-ignore
                onChange={setAdress} 
                httpCache={true}
                httpCacheTtl={600000}
                delay={500}
                autoload={true} 
            />
        </div>
    );
}

const Select = ({id, title, isClearable = false,  items,  state,  setState,  disabled = false,  style,  index,}) => {
    const [localState, setLocalState] = useState();

    useEffect(() => {
        setLocalState(state);
    }, [state]);
    
    const onChange = (data) => {
        if (index !== undefined) {
          setState(data, index);
        } else {
          setState(data);
        }
    };

    const Select = styled.div`
        position: relative;
        width: 100%;
        fontSize: 14px;
        userSelect: none;
        margin-top: 15px;
    `
    const Span = styled.span`
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #363636;
        margin-bottom: 10px;
    `
    return (
        <Select>
            <Span>{title}</Span>
            <SelectModule
                instanceId={id}
                key={new Date().toISOString()}
                defaultValue={localState ? localState : state}
                value={localState}
                onChange={onChange}
                options={items}
                placeholder={"Выбрать..."}
                isDisabled={disabled}
                isClearable={isClearable}
                noOptionsMessage={({ inputValue }) => !inputValue && "Нет элементов"}
                theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                    ...theme.colors,
                    primary25: "#FFB32025",
                    primary75: "#FFB32075",
                    primary50: "#FFB32050",
                    primary: "#FFB320",
                    neutral90: "black",
                    neutral5: "#ffffff",
                },
                })}
                styles={{
                option: (styles, { isSelected }) => {
                    return {
                    ...styles,
                    color: isSelected ? "black" : "black",
                    };
                },
                menu: (base) => ({ ...base, zIndex: 99 }),
                }}
            />
        </Select>
    );
};
const Range = ({title, min, max , state, setState}) => {
  return (
      <div className='range'>
          <span>{title}</span>
          <div className='input'>
              <div className='block'>
                  <input id='input_range' type='number' value={state} min={min} max={max} step={10} onChange={e=>setState(Number(e.target.value))}/>
                  <span>М<sup><small>3</small></sup></span>
              </div>
              <div className='range_block'>
                  <input type="range" min={min} max={max} step={10} value={state} onChange={e=>setState(Number(e.target.value))} />
              </div>
          </div>
      </div>
  );
}

const MapItemModal = observer(() => {
    const [disabledScroll, activeScroll] = useScrolWindow()
    const [materialId, setMaterialId] = useState(null)


    const [adress, setAdress] = useState('')
    const [vehicle, setVehicle] = useState('')
    const [volume, setVolume] = useState(20)

    const [adressError, setAdressError] = useState('')
    const [vehicleError, setVehicleError] = useState('')
    const [volumeError, setVolumeError] = useState('')

    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [result, setResult] = useState(null)


    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    const [contactId, setContactId] = useState(0);

    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setTimeout(()=>{
            setTrace(window.b24Tracker.guest.getTrace())
        },1000)
    });

    const [trace, setTrace] = useState('')

    useEffect(()=>{
        if(calculatorStore.state.modalMapItemActive) {
            disabledScroll()
        }
         else {
            activeScroll()
         }
    }, [calculatorStore.state.modalMapItemActive])
    
    useEffect(()=>{
        if(calculatorStore.state.targerMapId) {
            calculatorStore.getQuarryByTargetId()
        }
    }, [calculatorStore.state.targerMapId])
    
    const close = () => {
        setMaterialId(null)
        setResult(null)
        calculatorStore.switchModalMapItemActive(false)
    }

    const back = () => {
        setMaterialId(null)
        setResult(null)
    }

    useEffect(()=>{
        if(adress !== '') {
            setAdressError('')
        }
    }, [adress])
        
    useEffect(()=>{
        if(vehicle !== '') {
            setVehicleError('')
        }
    }, [vehicle])
    
    useEffect(()=>{
        if(volume !== '') {
            setVolumeError('')
        }
    }, [volume])

    useEffect(()=>{
        if(name !== '') {
            setNameError('')
        }
    }, [name])

    useEffect(()=>{
        if(phone !== '') {
            setPhoneError('')
        }
    }, [phone])

    const startCalculation = async() => {
        setResult(null)
        function checkValid() {
            let flag = true
            if(adress === '') {
                setAdressError('Укажите адрес доставки')
                flag = false
            }
            if(vehicle === '') {
                setVehicleError('Выберите транспорт')
                flag = false
            }
            if(volume < 1) {
                setVolumeError('Объем не может быть меньше 1')
                flag = false
            }
            return flag
        }
    
        if (checkValid()) {
            let buyerCoordinat = null
            let distance = null
            let culcalutionResult = null
            let extras = null
            await axios.post(`https://api.nerudkom.ru/geocode/get`, {string: adress.value, type:'point'})
            .then(response => {
                if(response.data) {
                    buyerCoordinat = response.data.geocode
                }
            });
            if(buyerCoordinat) {
                await axios.post(`https://api.nerudkom.ru/distance/get`, {coordinates: [quarry.coordinates], buyerCoordinates:buyerCoordinat})
                .then(response => {
                    if(response.data) {
                        distance = response.data.distance[0]
                    }
                });
            }
            if(distance) {
                culcalutionResult = {distance}
                if(quarry.materials[materialId].price[0].weight <= volume) {
                    culcalutionResult.price = Number(quarry.materials[materialId].price[0].cost)
                } else
                if (quarry.materials[materialId].price[1].minWeight >= volume && quarry.materials[materialId].price[1].maxWeight <= volume ) {
                    culcalutionResult.price = Number(quarry.materials[materialId].price[1].cost)
                } else 
                if (quarry.materials[materialId].price[2].weight >= volume) {
                    culcalutionResult.price = Number(quarry.materials[materialId].price[2].cost)
                }

                for (let index = 0; index < quarry.delivery.values.length; index++) {
                    let item = quarry.delivery.values[index]
                    if(item.value >= distance) {
                        if(vehicle.value === 'dumpTruck' && item.dumpTruck !== 0) {
                            let bet = item.dumpTruck * distance
                            if (quarry.delivery.dumpTruck > bet) {
                                bet = quarry.delivery.dumpTruck
                            }
                            culcalutionResult.deliveryBet = bet
                        }
                        if(vehicle.value === 'tonar' && item.tonnar !== 0) {
                            let bet = item.tonnar * distance
                            if (quarry.delivery.tonnar > bet) {
                                bet = quarry.delivery.tonnar
                            }
                            culcalutionResult.deliveryBet = bet
                        }
                        break
                    }
                }
                if(culcalutionResult.deliveryBet === undefined) {
                    setResult({error: 'К сожалению не сможем доставить данный материал на ваш адрес('})
                }
            }
            if(culcalutionResult.deliveryBet && culcalutionResult.price) {
                await axios.get(`https://api.nerudkom.ru/items/extra/get`)
                .then(response => {
                    if(response.data) {
                        extras = response.data.extra[0].data
                    }
                });
            }
            if(extras) {
                for (let index = 0; index < extras.length; index++) {
                    let item = extras[index]
                    if(item.sum >= ((culcalutionResult.price + culcalutionResult.deliveryBet) * volume)) {
                        culcalutionResult.extraValue = item.percent / 100
                        break
                    }
                }
            }
            if (culcalutionResult.extraValue) {
                culcalutionResult.fullPrice = ((culcalutionResult.price + culcalutionResult.deliveryBet) * volume) + (((culcalutionResult.price + culcalutionResult.deliveryBet) * volume) * culcalutionResult.extraValue)
            }
            if(culcalutionResult.fullPrice) {
                setResult(culcalutionResult)
            } else {
                setResult({error: 'К сожалению не сможем доставить данный материал на ваш адрес('})
            }
        }
    }


    const sendLead = () => {
        function checkValid() {
            let flag = true
            if(name === '') {
                setNameError('Укажите имя')
                flag = false
            }
            if(phone < 1) {
                setPhoneError('Укажите телефон')
                flag = false
            }
            return flag
        }
        const message = `
            [B]Расчет стоимости из калькулятора[/B] %0D%0A

            Площадка: [B]${quarry.name}[/B] %0D%0A

            Материал: [B]Щебень вторичный бетонный[/B] %0D%0A

            Фракция: [B]${quarry.materials[materialId].options[0]}[/B] %0D%0A

            Транспорт: [B]${vehicle.label}[/B] %0D%0A

            Дистанция: [B]${result.distance}км[/B] %0D%0A

            Себестоимость материала за 1м³: [B]${result.price} руб[/B] %0D%0A

            Себестоимость доставки за 1м³: [B]${Math.ceil(result.deliveryBet)} руб[/B] %0D%0A

            Наценка: [B]${result.extraValue * 100}%[/B] %0D%0A

            Стоимость материала за 1м³: [B]${Math.ceil(result.price + (result.price * result.extraValue))} руб[/B] %0D%0A

            Стоимость доставки за 1м³: [B]${Math.ceil(result.deliveryBet + (result.deliveryBet * result.extraValue))} руб[/B] %0D%0A

            КП: [B]${Math.ceil((result.price + result.deliveryBet) + ((result.price + result.deliveryBet)* result.extraValue))} руб[/B] %0D%0A

            Общая стоимость за [B]${volume}м³[/B] с доставкой: [B]${Math.ceil(result.fullPrice)} руб[/B]%0D%0A

            Прибыль: [B]${result.fullPrice*result.extraValue} руб[/B]
        `
        if(checkValid()) {
            if(contactId !== 0) {
                dealCreate(`FIELDS[CONTACT_ID]=${contactId}&FIELDS[CATEGORY_ID]=${config.CATEGORY_ID}&FIELDS[TITLE]=${'Калькулятор на карте: ' + config.title}&FIELDS[${config.pole_name}]=${name}&FIELDS[${config.pole_phone}]=${phone}&FIELDS[COMMENTS]=${message}&FIELDS[TRACE]=${trace}`)
            } else {
                contactCreate(`FIELDS[NAME]=${name}&FIELDS[PHONE][0][VALUE]=${phone}&FIELDS[PHONE][0][VALUE_TYPE]=WORK`, `FIELDS[CATEGORY_ID]=${config.CATEGORY_ID}&FIELDS[TITLE]=${'Калькулятор на карте: ' + config.title}&FIELDS[${config.pole_name}]=${name}&FIELDS[${config.pole_phone}]=${phone}&FIELDS[COMMENTS]=${message}&FIELDS[TRACE]=${trace}`)
            }
        }
    }

    const dealCreate = async (params) => {
        await fetch(`${config.bitrix24WebHook}/crm.deal.add.json?${params}`)
        .then(res => res.json())
        .then(
            (result) => {
                if(result) {
                    window.ym(80314183,'reachGoal','map_calculator')
                    close()
                }
            },
            (error) => {
            }
        )
}
    const contactCreate = async (params, params2) => {
        await fetch(`${config.bitrix24WebHook}/crm.contact.add.json?${params}`)
        .then(res => res.json())
        .then(
            (result) => {
                setContactId(result.result)
                dealCreate(`FIELDS[CONTACT_ID]=${result.result}&` + params2)
            },
            (error) => {
            }
        )
    }


    const quarry = calculatorStore.state.targerQuarry


    return (
        calculatorStore.state.modalMapItemActive &&
        quarry &&
        <div className="modal"  onClick={()=>{calculatorStore.switchModalMapItemActive(false)}}>
        {
            materialId === null ?
                <div className="block" onClick={(e)=>{e.stopPropagation()}}>
                    <h1>{quarry.name}</h1>
                    {/* <span>Полный адрес: <b>{quarry.adress}</b></span> */}
                    <h2>Цены на материалы</h2>
                    <div className='materials'>
                        {
                            quarry.materials.map((material, i)=>{
                                if(material?.id === '647886dbbe9730fa8a827b47') {
                                    return (
                                        <div className='material' key={i}>
                                            <div className="info">
                                                <h4>Щебень вторичный бетонный</h4>
                                                <ul>
                                                    <li>Фракция:<span>{material?.options[0]}</span></li>
                                                    <li>Качество:<span>{material?.options[1]}</span></li>
                                                </ul>
                                            </div>
                                            <div className="line"/>
                                            <div className="price">
                                                <div className="number">
                                                    <span>Цена за единицу</span>                                            
                                                    <span>от <b>{Math.ceil(material?.price[2]?.cost*1.15)}₽</b>/ {!material?.shipment ? 'м³' : 'тн'}</span> 
                                                </div>
                                                <div className="btn" onClick={()=>{setMaterialId(i)}}>Рассчитать стоимость</div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            })
                        }
                        
                    </div>
                    <div className="close" onClick={()=>{close()}}/>
                </div>
            :
                <div className="block" onClick={(e)=>{e.stopPropagation()}}>
                    <span className='back' onClick={()=>{back()}}>{'<'} Назад</span>
                    <h1>Калькулятор стоимости</h1>
                    <h2>Производственная площадка: {quarry.name}</h2>
                    <div className='materials'>
                        <div className='material'>
                            <div className="info">
                            <h4>Щебень вторичный бетонный</h4>
                            <ul>
                                <li>Фракция:<span>{quarry.materials[materialId].options[0]}</span></li>
                                <li>Качество:<span>{quarry.materials[materialId].options[1]}</span></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    {
                        result === null ?
                            <div className="form">
                                <InputAdressHelp adress={adress} setAdress={setAdress}/>
                                {
                                    adressError !== '' &&
                                    <span className='error'>{adressError}</span>
                                }
                                <Select title='Выберите транспорт' state={vehicle} setState={setVehicle} items={[{value:'dumpTruck', label: 'Самосвал 20-25м³'},{value:'tonar', label: 'Тонар 34м³ (полуприцеп)'}]}/>
                                {
                                    vehicleError !== '' &&
                                    <span className='error'>{vehicleError}</span>
                                }
                                <Range title={'Выберите объем'} min={20} max={9999999} state={volume} setState={setVolume}/>
                                {
                                    volumeError !== '' &&
                                    <span className='error'>{volumeError}</span>
                                }
                                <div className="btn" onClick={()=>{startCalculation()}}>Раcсчитать стоимость</div>
                            </div>
                        :
                        <div className="result">
                        <span className='title'>Результат расчета</span>
                        <span className='label'>Расстояние от площадки до вашего объекта: <span className='value'>{result.distance} км</span></span>
                        <span className='label'>Стоимость материала за 1м³: <span className='value'>{Math.ceil(result.price + (result.price * result.extraValue))} руб</span></span>
                        <span className='label'>Стоимость доставки за 1м³: <span className='value'>{Math.ceil(result.deliveryBet + (result.deliveryBet * result.extraValue))} руб</span></span>
                            <span className='label'>Стоимость за 1м³ с доставкой: <span className='value'>{Math.ceil((result.price + result.deliveryBet) + ((result.price + result.deliveryBet)* result.extraValue))} руб</span></span>
                            <span className='label'>Общая стоимость за {volume}м³ с доставкой: <span className='value'>{Math.ceil(result.fullPrice)} руб</span></span>
                            <div className="form">
                                <span className='offer'>Заполните контактные данные,<br/> менеджер свяжется с вами в течении 15 минут</span>
                                <div className="input">
                                    <span className='title'>Имя</span>
                                    <div className="input-block">
                                        <input type="text" value={name} onChange={e=>setName(e.target.value)}/>
                                    </div>
                                </div>
                                {
                                    nameError !== '' &&
                                    <span className='error'>{nameError}</span>
                                }
                                <div className="input">
                                    <span className='title'>Телефон</span>
                                    <div className="input-block">
                                        <InputMask 
                                            mask='+7(999)999-99-99' 
                                            value={phone} 
                                            onChange={e=>setPhone(e.target.value)}
                                            type={'phone'}
                                            autoComplete="new-password">
                                        </InputMask>
                                    </div>
                                </div>
                                {
                                    phoneError !== '' &&
                                    <span className='error'>{phoneError}</span>
                                }
                                <div className="btn" onClick={()=>{sendLead()}}>Заказать</div> 
                            </div>
                        </div>
                    }
                    
                    <div className="close" onClick={()=>{close()}}/>
                </div>
        }
            
        </div>
    );
})

export default MapItemModal;