import React from 'react';

import './advantages.scss'
import './adaptive.scss'

const Advantages = () => {
    return ( 
        <React.Fragment>
            <div className="advantages">
                <div className="container">
                    <div className="content">
                        <div className="item">
                            <div className="ico cash"/>
                            <span>Оплата по счету или картой</span>
                        </div>
                        <div className="item">
                            <div className="ico tfs"/>
                            <span>Отгружаем круглосуточно <br/>без выходных</span>
                        </div>
                        <div className="item">
                            <div className="ico geo"/>
                            <span>Доставка по Москве и МО</span>
                        </div>
                        <div className="item">
                            <div className="ico truck"/>
                            <span>Больше 1000 самосвалов <br/>на линии</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment> 
    );
}

export default Advantages;