import axios, { formToJSON } from "axios";
import { makeAutoObservable } from "mobx"

class CalculatorStore {
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }
    state = {
        qarrys: [],
        fractions: [],
        targetData: [],
        modalMapItemActive: false,
        targerMapId: null,
        targerQuarry: null
    }

    getQuarrys = async () => {
        await axios.get(`https://api.nerudkom.ru/items/quarry/get`)
        .then((response)=>{
          if(response.data) {
            const fractions = []
            this.state.qarrys = response.data.quarrys
            response.data.quarrys.map((quarry, i)=>{
              let id = null
              quarry.materials.map((material)=>{
                if(material.id === '647886dbbe9730fa8a827b47' && id === null) {
                  let data = quarry
                  data.coordinates = [Number(data.coordinates.split(/[ ,]+/)[0]), Number(data.coordinates.split(/[ ,]+/)[1])]
                  this.state.targetData.push(data)
                  id = quarry.id
                }
                if(material.id === '647886dbbe9730fa8a827b47' && material.stocks === 'inStock') {
                  if( fractions.indexOf( material.options[0]) === -1) {
                    fractions.push(material.options[0])
                  }
                }
              })
            })
           this.state.fractions = fractions
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
    getQuarryById = (id) => {
      for (let index = 0; index < this.state.qarrys.length; index++) {
        if(this.state.qarrys[index]._id === id) {
          return this.state.qarrys[index]
        }
      }
    }

    getQuarryByTargetId = () => {
      if(this.state.targerMapId) {
        this.state.qarrys.map((data)=>{
          if(data._id === this.state.targerMapId) {
            this.state.targerQuarry = data
          }
        })
      }
    }

    getMinimalPrice = (fraction) => {
      let data = []
      for (let index = 0; index < this.state.targetData.length; index++) {
        const item = this.state.targetData[index]
        for (let a = 0; a < item.materials.length; a++) {
          const material = item.materials[a]          
          if(material.options[0] === fraction) {
            if(material.shipment) {
              data.push(material.price[2].cost*material.ration)
            } else {
              data.push(material.price[2].cost)
            }
          }         
        }
      }
      console.log(data)
      return Math.min(...data)
    }

    switchModalMapItemActive = (boolean) => {
      this.state.modalMapItemActive = boolean
    }

    setTargetMap = (id) => {
      this.state.targerMapId = id
    }

}
export default new CalculatorStore()