import { useCallback, useEffect, useRef, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import SelectModule from "react-select";
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import config from "../../config";
import calculatorStore from './calculator.store';
import axios from 'axios';

import './style.scss'



const InputAdressHelp = ({adress, setAdress}) => {
    return (
        <div className='input'>
            <span className='title'>Укажите адрес доставки</span>
            <AddressSuggestions
                token="1fab525a0826da67fbc9fbe9769bfda7da49c76d" 
                inputProps={
                    {placeholder: 'Например: Москва ул. Кадомцев, д. 3'}
                }
                // @ts-ignore
                value={adress}
                 // @ts-ignore
                onChange={setAdress} 
                httpCache={true}
                httpCacheTtl={600000}
                delay={500}
                autoload={true} 
            />
        </div>
    );
}

const Select = ({id, title, isClearable = false,  items,  state,  setState,  disabled = false,  style,  index,}) => {
    const [localState, setLocalState] = useState();

    useEffect(() => {
        setLocalState(state);
    }, [state]);
    
    const onChange = (data) => {
        if (index !== undefined) {
          setState(data, index);
        } else {
          setState(data);
        }
    };

    const Select = styled.div`
        position: relative;
        width: 100%;
        fontSize: 14px;
        userSelect: none;
        margin-top: 15px;
    `
    const Span = styled.span`
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #363636;
        margin-bottom: 10px;
    `
    return (
        <Select>
            <Span>{title}</Span>
            <SelectModule
                instanceId={id}
                key={new Date().toISOString()}
                defaultValue={localState ? localState : state}
                value={localState}
                onChange={onChange}
                options={items}
                placeholder={"Выбрать..."}
                isDisabled={disabled}
                isClearable={isClearable}
                noOptionsMessage={({ inputValue }) => !inputValue && "Нет элементов"}
                theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                    ...theme.colors,
                    primary25: "#FFB32025",
                    primary75: "#FFB32075",
                    primary50: "#FFB32050",
                    primary: "#FFB320",
                    neutral90: "black",
                    neutral5: "#ffffff",
                },
                })}
                styles={{
                option: (styles, { isSelected }) => {
                    return {
                    ...styles,
                    color: isSelected ? "black" : "black",
                    };
                },
                menu: (base) => ({ ...base, zIndex: 99 }),
                }}
            />
        </Select>
    );
};
const Range = ({title, min, max , state, setState}) => {
  return (
      <div className='range'>
          <span>{title}</span>
          <div className='input'>
              <div className='block'>
                  <input id='input_range' type='number' value={state} min={min} max={max} step={10} onChange={e=>setState(Number(e.target.value))}/>
                  <span>М<sup><small>3</small></sup></span>
              </div>
              <div className='range_block'>
                  <input type="range" min={min} max={max} step={10} value={state} onChange={e=>setState(Number(e.target.value))} />
              </div>
          </div>
      </div>
  );
}

const CalcualtorBlock = observer(() => {
    const [adress, setAdress] = useState('')
    const [fraction, setFraction] = useState('')
    const [vehicle, setVehicle] = useState('')
    const [volume, setVolume] = useState(20)

    const [adressError, setAdressError] = useState('')
    const [fractionError, setFractionError] = useState('')
    const [vehicleError, setVehicleError] = useState('')
    const [volumeError, setVolumeError] = useState('')

    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [result, setResult] = useState(null)

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    const [contactId, setContactId] = useState(0);

    useEffect(() => {
        setTimeout(()=>{
            setTrace(window.b24Tracker.guest.getTrace())
        },1000)
    });

    const [trace, setTrace] = useState('')

    const getFraction = () => {
      const data = []
      calculatorStore.state.fractions.map((item, i) => {
          data.push({value: i, label: item})
      })
      return data
  }

  useEffect(()=>{
    if(adress !== '') {
        setAdressError('')
    }
    }, [adress])

    useEffect(()=>{
        if(fraction !== '') {
            setFractionError('')
        }
    }, [fraction])

    useEffect(()=>{
        if(vehicle !== '') {
            setVehicleError('')
        }
    }, [vehicle])

    useEffect(()=>{
        if(volume !== '') {
            setVolumeError('')
        }
    }, [volume])

    useEffect(()=>{
        if(name !== '') {
            setNameError('')
        }
    }, [name])

    useEffect(()=>{
        if(phone !== '') {
            setPhoneError('')
        }
    }, [phone])

    const startCalculation = () => {
        setResult(null)
        function checkValid() {
            let flag = true
            if(adress === '') {
                setAdressError('Укажите адрес доставки')
                flag = false
            }
            if(fraction === '') {
                setFractionError('Выберите фракцию')
                flag = false
            }
            if(vehicle === '') {
                setVehicleError('Выберите транспорт')
                flag = false
            }
            if(volume < 1) {
                setVolumeError('Объем не может быть меньше 1')
                flag = false
            }
            return flag
        }

        if (checkValid()) {
            axios.post(`https://api.nerudkom.ru/calculator/getPriceMap`, 
            {
                culcData:{
                    "addres": adress.value,
                    "materialID": "647886dbbe9730fa8a827b47",
                    "selectOptions": [fraction.label],
                    "vehicle": vehicle.value,
                    "volume": volume
                }
            })
            .then(response => {
                if(response.data.results.length > 0) {
                    setResult(response.data.results[0]);
                    console.log(response.data.results[0])
                }
            });
        }
    }

    const sendLead = () => {
        function checkValid() {
            let flag = true
            if(name === '') {
                setNameError('Укажите имя')
                flag = false
            }
            if(phone < 1) {
                setPhoneError('Укажите телефон')
                flag = false
            }
            return flag
        }
        const message = `
            [B]Расчет стоимости из калькулятора[/B] %0D%0A

            Площадка: [B]${calculatorStore.getQuarryById(result.quarryId).name}[/B] %0D%0A

            Материал: [B]Щебень вторичный бетонный[/B] %0D%0A

            Фракция: [B]${fraction.label}[/B] %0D%0A

            Транспорт: [B]${vehicle.label}[/B] %0D%0A

            Дистанция: [B]${result.distance}км[/B] %0D%0A

            КП: [B]${result.price} руб[/B] %0D%0A

            Общая стоимость за [B]${volume}м³[/B] с доставкой: [B]${result.totalPrice} руб[/B]
        `
        console.log(message)
        if(checkValid()) {
            if(contactId !== 0) {
                dealCreate(`FIELDS[CONTACT_ID]=${contactId}&FIELDS[CATEGORY_ID]=${config.CATEGORY_ID}&FIELDS[TITLE]=${'Калькулятор блок: ' + config.title}&FIELDS[${config.pole_name}]=${name}&FIELDS[${config.pole_phone}]=${phone}&FIELDS[COMMENTS]=${message}&FIELDS[TRACE]=${trace}`)
            } else {
                contactCreate(`FIELDS[NAME]=${name}&FIELDS[PHONE][0][VALUE]=${phone}&FIELDS[PHONE][0][VALUE_TYPE]=WORK`, `FIELDS[CATEGORY_ID]=${config.CATEGORY_ID}&FIELDS[TITLE]=${'Калькулятор блок: ' + config.title}&FIELDS[${config.pole_name}]=${name}&FIELDS[${config.pole_phone}]=${phone}&FIELDS[COMMENTS]=${message}&FIELDS[TRACE]=${trace}`)
            }
        }
    }
    const dealCreate = async (params) => {
        await fetch(`${config.bitrix24WebHook}/crm.deal.add.json?${params}`)
        .then(res => res.json())
        .then(
            (result) => {
                if(result) {
                    window.ym(80314183,'reachGoal','block_calculator')
                    setResult(null)
                }
            },
            (error) => {
            }
        )
    }
    const contactCreate = async (params, params2) => {
        await fetch(`${config.bitrix24WebHook}/crm.contact.add.json?${params}`)
        .then(res => res.json())
        .then(
            (result) => {
                setContactId(result.result)
                dealCreate(`FIELDS[CONTACT_ID]=${result.result}&` + params2)
            },
            (error) => {
            }
        )
    }



    return (
        <div className='calcualtor_block'>
            <div className='calculator_loyuot'>
                <InputAdressHelp adress={adress} setAdress={setAdress}/>
                {
                    adressError !== '' &&
                    <span className='error'>{adressError}</span>
                }
                <Select title='Выберите фракцию' state={fraction} setState={setFraction} items={getFraction()}/>
                {
                    fractionError !== '' &&
                    <span className='error'>{fractionError}</span>
                }
                <Select title='Выберите транспорт' state={vehicle} setState={setVehicle} items={[{value:'dumpTruck', label: 'Самосвал 20-25м³'},{value:'tonar', label: 'Тонар 34м³ (полуприцеп)'}]}/>
                {
                    vehicleError !== '' &&
                    <span className='error'>{vehicleError}</span>
                }
                <Range title={'Выберите объем'} min={20} max={9999999} state={volume} setState={setVolume}/>
                {
                    volumeError !== '' &&
                    <span className='error'>{volumeError}</span>
                }
                <div className="btn" onClick={()=>{startCalculation()}}>Раcсчитать стоимость</div>
            </div>
            {
                result !== null &&
                <div className='calculator_result'>
                    <span className='title'> Результат расчета</span>
                        <span className='label'>Материал: <span className='value'>Щебень вторичный бетонный</span></span>
                        <span className='label'>Фракция: <span className='value'>{result.material.options[0]}</span></span>
                        <span className='label'>Стоимость за 1м³ с доставкой: <span className='value'>{result.price} руб</span></span>
                        <span className='label'>Общая стоимость за {result.volume}м³ с доставкой: <span className='value'>{result.totalPrice} руб</span></span>
                        <div className="form">
                            <span className='offer'>Заполните контактные данные,<br/> менеджер свяжется с вами в течении 15 минут</span>
                            <div className="input">
                                <span className='title'>Имя</span>
                                <div className="input-block">
                                    <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
                                </div>
                            </div>
                            {
                                nameError !== '' &&
                                <span className='error'>{nameError}</span>
                            }
                            <div className="input">
                                <span className='title'>Телефон</span>
                                <div className="input-block">
                                    <InputMask 
                                        mask='+7(999)999-99-99' 
                                        value={phone} 
                                        onChange={e=>setPhone(e.target.value)}
                                        type={'phone'}
                                        autoComplete="new-password">
                                    </InputMask>
                                </div>
                            </div>
                            {
                                phoneError !== '' &&
                                <span className='error'>{phoneError}</span>
                            }
                            <div className="btn" onClick={()=>{sendLead()}}>Заказать</div> 
                        </div>
                </div>
            }
            {
                result === null &&
                <div className='info_calculator'>
                    <p>
                        Наш калькулятор нерудных материалов поможет Вам рассчитать точную
                        стоимость доставки материала.
                        <br />
                        Все, что Вам нужно сделать, это ввести адрес доставки, выбрать материал
                        и объем доставляемых материалов. И Вы получите точную стоимость доставки
                        в режиме реального времени.
                    </p>
                    <div className='numbers'>
                        <div className='number'>
                        <b>5 секунд</b>
                        <span>время расчёта</span>
                        </div>
                        <div className='number'>
                        <b>{calculatorStore.state.targetData.length}</b>
                        <span>площадок</span>
                        </div>
                        {/* <div className='number'>
                        <b>700</b>
                        <span>видов материала</span>
                        </div> */}
                        <div className='number'>
                        <b>300</b>
                        <span>рассчётов в день</span>
                        </div>
                        <div className='number'>
                        <b>2 млн. тонн</b>
                        <span>реализуемой продукции в год</span>
                        </div>
                        <div className='number'>
                        <b>100%</b>
                        <span>точная стоимость</span>
                        </div>
                        {/* <div className='number'>
                        <b>{">"} 1000</b>
                        <span>самосвалов на линии</span>
                        </div> */}
                    </div>
                </div>
            }
            
        </div>
    );
})

export default CalcualtorBlock;