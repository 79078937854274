import React, { useEffect, useState } from "react";
import { YMaps, Map, Placemark, FullscreenControl, Clusterer } from '@pbe/react-yandex-maps';
import './map.scss'
import './adaptive.scss'
import calculatorStore from "../../../calcultor/calculator.store";
import { observer } from "mobx-react-lite";

const MapBlock = observer(() => {
    const defaultState = {
        center: [55.547447, 38.040618],
        zoom: 8,
        controls: [],
    }
    const onPlacemarkClick = (id) => {
        calculatorStore.setTargetMap(id)
        calculatorStore.switchModalMapItemActive(true)
    }
    return (
        <React.Fragment>
             <div className="map">
                 <div className="container">
                     <div className="content">
                         <div className="text">
                             <span className="title">Производственные площадки вторичного бетонного щебня</span>
                             <div className="description">
                                 <p>У нашей компании более {calculatorStore.state.targetData.length} производственных площадок, находящимися в разных местах Москвы и Московской области, благодаря этому мы можем подобрать Вам любой объём вторичного щебня с наиболее быстрым способом доставки за минимальные цены.</p>
                             </div>
                         </div>
                         <div id="map" className="mapBlock">
                         <YMaps>
                             <Map defaultState={defaultState} width={'100%'} height={500} modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}>
                                 <Clusterer
                                 options={{
                                     preset: `islands#orangeClusterIcons`,
                                     groupByCoordinates: false,
                                     maxZoom: 5
                                 }}
                                 >
                                 {calculatorStore.state.targetData.map((data, index) => (
                                     <Placemark
                                     key={`${index}-${data.coordinates}`}
                                     geometry={data.coordinates}
                                     onClick={()=>{onPlacemarkClick(data._id)}}
                                     options={{
                                         iconLayout: 'default#image',
                                         iconImageHref: `map_img/647886dbbe9730fa8a827b47.png`,
                                         iconImageSize: [21, 21],
                                         iconImageOffset: [-20, -20],
                                         iconShape: {
                                         type: 'Circle',
                                         //@ts-ignore
                                         coordinates: [0, 0],
                                         radius: 20
                                         },
                                     }}
                                     properties={{
                                         hintContent: `<em>${data.name}</em>`
                                     }}
                                     />
                                 ))}
                                 </Clusterer>
                                 <FullscreenControl />
                             </Map>
                         </YMaps>
                         </div>
                     </div>
                 </div>
             </div>
        </React.Fragment>
     );
})

export default MapBlock;